<template>
  <div class="ml-2">
    <div class="d-flex" style="align-items:flex-end;">
      <div class="col-md-3">
        <h3>Campaign</h3>
      </div>
      <div class="col-md-3">
        <multiselect
          v-model="selectedLoop"
          :options="loops"
          placeholder="All"
          :allow-empty="false"
          @input="handleLoopChange"
        >
          <template slot="singleLabel" slot-scope="props">
            <div>
              {{ props.option.label }}
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div>
              {{ props.option.label }}
            </div>
          </template>
        </multiselect>
      </div>
      <div class="col-md-3">
        <flat-pickr
          v-model="dates"
          class="form-control daterange-picker"
          :config="rangeConfig"
          name="date"
          :style="{ backgroundColor: '#FFF' }"
          @on-change="handleDateChange"
        ></flat-pickr>
      </div>
      <div class="col-md-3">
        <div class="card mb-0">
          <div class="card-body d-flex justify-content-between align-items-end">
            <h4>Total Points</h4> <h2>{{ stats.total_points }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div v-if="!loadingStats" class="col-md-12">
        <div class="row">
          <div v-for="(value, index) in stats.cards" :key="index" class="col-md-4">
            <stats-card :items="value.items" :chart="value.chart" />
          </div>
        </div>
      </div>
      <div v-else class="col-md-12 text-center">
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mt-0 mb-3">Leader Board</h4>
            <div class="col-sm-12 col-md-4">
              <div class="dataTables_length d-inline">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="datatable.queries.per_page"
                    class="ml-1 mr-1"
                    size="sm"
                    :options="[10, 15, 25, 50, 100, 'All']"
                    @change="getContacts"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <template v-if="!loadingLeaderBoard">
                <b-table
                  :items="contacts"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :sort-direction="datatable.queries.sort_direction"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <template v-slot:cell(name)="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>

                  <template v-slot:cell(total_amount)="data">
                    {{ data.item.total_amount | currency }}
                  </template>

                  <template v-slot:cell(referrals_revenue)="data">
                    {{ data.item.referrals_revenue | currency }}
                  </template>
                </b-table>
                <div class="row mt-4">
                  <div class="col">
                    <div class="float-left mt-2">
                      Total {{ totalContacts }} contacts
                    </div>
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="totalContacts"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="text-center">
                <b-spinner></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import StatsCard from '../../Common/StatsCard.vue'
import moment from 'moment-timezone'

export default {
  components: {
    Multiselect,
    StatsCard,
  },

  data() {
    return {
      datatable: {
        columns: [
          { key: 'position', label: 'Position', sortable: false },
          { key: 'name', label: 'Name', sortable: false },
          { key: 'phone', label: 'Phone', sortable: false },
          { key: 'points', label: 'Points', sortable: false },
          { key: 'rewards', label: 'Rewards', sortable: false },
          { key: 'total_amount', label: 'Revenue', sortable: false },
          { key: 'referrals', label: 'Referrals', sortable: false },
          { key: 'referrals_revenue', label: 'Referrals Revenue', sortable: false },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'position',
          sort_desc: true,
          sort_direction: 'asc',
          selectedLoop: null,
          selectedDates: [],
        },
      },
      rangeConfig: {
        mode: 'range',
        altInput: true,
        altFormat: "M j, Y",
      },
      loadingCoupons: false,
      loadingStats: false,
      selectedDates: [ moment().subtract(30, 'days').format(), moment().format() ],
      dates: [ moment().subtract(30, 'days').format(), moment().format() ],
      selectedLoop: { id: 0, label: 'All' },
      stats: [],
      loadingLeaderBoard: false,
    }
  },

  computed: {
    loops() {
      let coupons = this.$store.getters['coupon/all']
      if (!coupons) {
        return []
      }
      let loops = [ { id: 0, label: 'All' } ]
      coupons.forEach((coupon) => {
        if (coupon.type === 'loop')
          loops.push({
            id: coupon.id,
            label: coupon.name,
          })
      })

      return loops
    },

    contacts() {
      let contacts = this.$store.getters['contact/all']
      if (!contacts) {
        return []
      }
      contacts.sort((a, b) => {
        return b.points - a.points
      })
      contacts = contacts.map((contact, index) => {
        contact.position = ((this.datatable.queries.page - 1) * this.datatable.queries.per_page ) + index + 1
        return contact
      })
      return contacts
    },

    totalContacts() {
      return this.$store.getters['contact/total']
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getCoupons()
      this.getStats()
    })
  },

  methods: {
    getCoupons() {
      this.loadingCoupons = true
      this.$store
        .dispatch('coupon/getAllLoops')
        .then(() => {
          this.loadingCoupons = false
        })
        .catch(() => {
          this.loadingCoupons = false
        })
    },

    getStats() {
      this.loadingStats = true;
      let queries = {}
      if (this.selectedLoop) {
        queries['loop'] = this.selectedLoop.id
      }

      if (this.selectedDates.length === 2) {
        queries['selectedDates'] = this.selectedDates
      }

      this.$store
        .dispatch('coupon/getStats', queries)
        .then((res) => {
          this.stats = res
          this.loadingStats = false
        })
        .catch(() => {
          this.loadingStats = false
        })

      this.getContacts()
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getContacts()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getContacts()
    },

    getContacts() {
      this.loadingLeaderBoard = true

      if (this.selectedLoop) {
        this.datatable.queries.selectedLoop = this.selectedLoop.id
      }

      if (this.selectedDates.length === 2) {
        this.datatable.queries.selectedDates = this.selectedDates
      }

      this.$store
        .dispatch('contact/getLeaderBoard', this.datatable.queries)
        .then(() => {
          this.loadingLeaderBoard = false
        })
        .catch(() => {
          this.loadingLeaderBoard = false
        })
    },

    handleLoopChange() {
      this.getStats()
    },

    handleDateChange(selectedDates) {
      if (selectedDates.length !== 2) {
        return
      }

      this.selectedDates = selectedDates

      this.getStats()
    },
  },
}
</script>

<style lang="scss">
  .daterange-picker {
    background: #fff!important;
  }
</style>
