<template>
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex"
              style="justify-content: space-evenly; align-items: flex-end;"
            >
              <div v-for="(item, index) of items" :key="index" class="">
                <!-- eslint-disable vue/no-v-html -->
                <h5 class="text-center" v-html="item.label"> {{ item.label }} </h5>
                <!--eslint-enable-->
                <p class="text-center"><span v-if="item.type">{{ item.value | currency}}</span><span v-else>{{ item.value }}</span></p>

              </div>
              <div v-if="chart" style="width:33%" >
                <div class="d-flex single-chart" >
                  <svg viewBox="0 0 36 36" class="circular-chart" :class="chart.color">
                    <path
                      class="circle-bg"
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      class="circle"
                      :stroke-dasharray="chart.conversion_output"
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" class="percentage">{{ chart.conversion_rate }}%</text>
                  </svg>
                </div>
                <p class="text-center">Conversion Rate</p>
              </div>
            </div>
          </div>
        </div>
</template>

<script>

export default {
props: {
    color: {
      type: String,
      default: 'blue',
    },
    items: {
      type: Array,
      default: () => []
    },
    chart: {
      type: Object,
      default: null
    }
  },
}
</script>

<style lang="scss">
.single-chart {
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #ff9f00;
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
</style>